<!--
 * @Author: Haoxiwei 1078734933@qq.com
 * @Date: 2023-04-27 11:17:12
 * @LastEditors: Haoxiwei 1078734933@qq.com
 * @LastEditTime: 2024-09-04 11:28:49
 * @FilePath: /wemeet-admin-new/src/views/brand/components/addContact.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="800px"
    @close="close"
  >
    <vab-query-form>
      <vab-query-form-left-panel :span="12"></vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form inline>
          <el-form-item>
            <el-input placeholder="请输入手机号" v-model="phone"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchHostInfo(row)">
              添加
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      :data="list"
      :table-layout="tableLayout"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="name" label="姓名" />
      <el-table-column prop="phone" label="手机号" />
      <el-table-column prop="position" label="职位" />
      <el-table-column prop="dutyDescription" label="备注">
        <template #default="{ row }">
          <el-input v-model="row.dutyDescription" maxlength="50" v-if="row.isEdit"></el-input>
          <span v-else>{{ row.dutyDescription }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            {{ row.isEdit ? '保存' : '编辑' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import {
    queryContactInfoLibrary,
    relatedOrgInfo,
    addBatchContactInfo,
    addContactInfo,
    updateContactInfo,
    queryContactInfoByType,
  } from '@/api/brand'
  import { getUserByPhone } from '@/api/user'

  export default defineComponent({
    name: 'editRelated',
    emits: ['addUserInfo'],
    props: {
      code: {
        type: String,
        default: '',
      },
    },
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        size: 'large',
        tableLayout: 'auto',
        title: '添加联系人',
        dialogFormVisible: false,
        list: [],
        hostInfo: [],
        phone: '',
        code: '',
      })

      const showEdit = async (row) => {
        state.code = row.code
        state.dialogFormVisible = true
        const { data } = await queryContactInfoLibrary({
          code: row.code,
          type: 'EXHIBITOR',
          language: 'CN',
        })
        state.list = data ? data : []
      }
      const close = () => {
        state.form = { phone: '' }
        state.hostInfo = []
        state.dialogFormVisible = false
      }

      const searchHostInfo = async () => {
        if (!!state.phone) {
          try {
            let phoneParams = { phone: state.phone }
            const { data } = await getUserByPhone(phoneParams)
            await queryContactInfoByType({
              code: state.code,
              type: 'EXHIBITOR',
              language: 'CN',
            })
            try {
              let obj = {}
              obj.dutyDescription = ''
              obj.uid = data.uid
              obj.type = 'EXHIBITOR'
              obj.language = 'CN'
              obj.code = state.code
              obj.relationCode = state.code
              await addContactInfo(obj)
            } catch (e) {}
            data.isEdit = true
            const { data: list } = await queryContactInfoLibrary({
              code: state.code,
              type: 'EXHIBITOR',
              language: 'CN',
            })
            state.list = list ? list : []
          } catch (e) {}
        } else {
          proxy.$baseMessage(`手机号不能为空`, 'error', 'vab-hey-message-error')
        }
      }

      const handleEdit = async (row) => {
        let index = state.list.findIndex((v) => v.uid == row.uid)
        if (state.list[index].isEdit) {
          try {
            let obj = {}
            obj.dutyDescription = row.dutyDescription
            obj.uid = row.uid
            obj.type = 'EXHIBITOR'
            obj.language = 'CN'
            obj.code = state.code
            obj.relationCode = state.code
            obj.id = row.contactId
            await updateContactInfo(obj)
            state.list[index].isEdit = !state.list[index].isEdit
          } catch (e) {}
        } else {
          state.list[index].isEdit = !state.list[index].isEdit
        }
      }

      const save = async () => {
        if (state.hostInfo.length >= 1) {
          let exhibitorContacts = []
          state.hostInfo.map((res, index) => {
            let obj = {}
            obj.dutyDescription = res.dutyDescription
            obj.uid = res.uid
            obj.sort = index
            obj.type = 'EXHIBITOR'
            obj.language = 'CN'
            obj.code = state.code
            exhibitorContacts.push(obj)
          })
          await addBatchContactInfo(exhibitorContacts)

          try {
            emit('addUserInfo', state.hostInfo)
            close()
          } catch (e) {
            proxy.$baseMessage(
              `系统错误，请重试`,
              'error',
              'vab-hey-message-error'
            )
          }
        } else {
          proxy.$baseMessage(
            `用户信息不能为空`,
            'error',
            'vab-hey-message-error'
          )
        }
      }

      const handleSelectionChange = (val) => {
        state.hostInfo = val
      }
      return {
        ...toRefs(state),
        showEdit,
        close,
        searchHostInfo,
        save,
        handleEdit,
        handleSelectionChange,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }

  .userInfoBox {
    display: flex;
    justify-content: left;
    background: #67c23a;
    padding: 10px;
    box-sizing: border-box;
    .detailInfo {
      margin-left: 20px;
      color: #fff;
      font-size: 16px;
    }
  }
</style>
